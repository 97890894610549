import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import LatestNews from './LatestNews.js';
import { PostData } from './PostData';
// import ReactFlowPlayer from "react-flow-player";
import Flowplayer from "@flowplayer/react-flowplayer"

class Live extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            latestNews: [],
        }
    }

    render() {
        return (
            <div id="category">
                <Header />
                <div id="appCapsule">
                    <Menu active="home" />
                    <div className="tab-live-main">
                        <div className="appfullcontainer mt-0 mb-1" >
                            {/*<div className="">
                                <div itemScope itemType="http://schema.org/VideoObject">
                                    <meta itemProp="uploadDate" content="2016-05-16T23:18:21+0530" />
                                    <meta itemProp="name" content="goa 365" />
                                    <meta itemProp="contentUrl" content="http://iptv.mstudiosystem.com:1935/goa365/livestream3/playlist.m3u8" />
                                    <div >
                                        <iframe src="//content.jwplatform.com/players/nLfOdIlq-0wNgo3RQ.html" width="100%" height="250" frameBorder="0" scrolling="auto" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>*/}
                            <Flowplayer token="82b63cb3-cd47-46d0-ba77-b7a67c9c4bc2" src="//edge.flowplayer.org/bauhaus.mp4" />
                           {/* <ReactFlowPlayer
                              playerInitScript="http://releases.flowplayer.org/7.2.1/flowplayer.min.js"
                              playerId="82b63cb3-cd47-46d0-ba77-b7a67c9c4bc2"
                              sources={[
                                {
                                  src: "//asia.mslivestream.net/mslive/f9fe659e19d111baf97526ad526b55b4.sdp/playlist.m3u8"
                                }
                              ]}
                            />*/}
                            {/*<ReactFlowPlayer
                              playerInitScript="http://cdn.flowplayer.com/players/82b63cb3-cd47-46d0-ba77-b7a67c9c4bc2/native/flowplayer.async.js"
                              playerId="82b63cb3-cd47-46d0-ba77-b7a67c9c4bc2"
                              sources={[
                                {
                                  src: "http://asia.mslivestream.net/mslive/f9fe659e19d111baf97526ad526b55b4.sdp/playlist.m3u8"
                                }
                              ]}
                            />*/}
                            <div id="pagecont"><div data-player-id="c8478842-62c1-42d4-a8da-3a2647d0f010" style={{width: "470", height: "320"}}></div></div>
                            <div className="live-streaming-links">
                                <a href="fb://page/124218901316478" target="_blank" className="fb-live" rel="alternate">
                                    <img src={"/assets/img/fb.png"} />
                                </a>
                                <a href="https://www.youtube.com/channel/UCDFOkbaN9IuV6tjO8Aqo1ww" target="_blank" className="yt-live">
                                    <img src={"/assets/img/yt.png"} />
                                </a>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Live;