import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import { PostData } from './PostData';

class Bulletins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            bulletindata: [],
            bulletinNews: [],            
            language: props.match.params.type,
            bulletin_id: props.match.params.bid,
            bulletinNewsList : []
        }

        this.fetchBulletinPageData = this.fetchBulletinPageData.bind(this);
        this.shareClick = this.shareClick.bind(this);
    }

    componentDidMount() {
        this.fetchBulletinPageData()
    }

    fetchBulletinPageData() {

        let data = { language: this.state.language, id: this.state.bulletin_id, limit: 10 };

        PostData('BulletinNews', data).then((result) => {
            let responseJson = result;
            // console.log("bulletin data: "+responseJson);
            this.setState({ bulletinNews: responseJson });
        });
        
        let bulletindata = { limit: 'limit 4', language: 'E', cat_id: '' };
        PostData('BulletinHome', bulletindata).then((result) => {
            let responseJson = result;
            this.setState({ bulletinNewsList: responseJson });
        });
    }

    shareClick(e, share_url, share_title, share_text = "", share_image = "") {
        fetch(share_image, {
            mode: 'cors',
        })
        .then(function (response) {
            console.log(response);
            return response.blob()
        })
        .then(function (blob) {
            var file = new File([blob], share_image, { type: 'image/jpeg' });
            var filesArray = [file];
            console.log(filesArray);

            if (navigator.canShare && navigator.canShare({ files: filesArray })) {
                navigator.share({
                    text: share_text,
                    files: filesArray,
                    title: share_title,
                    url: share_url
                }).then(() => {
                    console.log('Thanks for sharing!');
                })
                    .catch(console.error);
            }
            else {
                console.log('device does not support sharing...');
            }
        });
    }

    /* shareClick(e, share_url, share_title) {
        console.log(share_title);

        if (navigator.share) {
            navigator.share({
                title: share_title,
                url: share_url
            }).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
            console.log('device does not support sharing...');
        }
    } */

    render() {
        return (
            <div id="bulletin">
                <Header />
                <div id="appCapsule">
                    <Menu active="bulletins" />
                    {this.state.bulletinNews.length > 1 &&
                        <div className="tab-bulletin">
                            <div className="appfullcontainer mt-0 mb-1" >
                                {this.state.bulletinNews.length > 0 &&
                                    this.state.bulletinNews.map((bulletinobj, i) => {
                                        if(i==0) {
                                            let title_str = "GOA 365 Bulletin: " + bulletinobj.title;
                                            return (
                                                <div className="live-block" key={i}>
                                                    <div className="card">
                                                        <div className="news-img-bx">
                                                            <iframe width="100%" height="250" src={bulletinobj.videolink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        </div>

                                                        <div className="card-body">
                                                            <p className="tag">{bulletinobj.dateofpost}</p>
                                                        </div>


                                                        <div className="share-bx">
                                                            <button onClick={(e) => this.shareClick(e, bulletinobj.videolink, "Goa 365 SHORTS: ", title_str, bulletinobj.VideoPic)} className="share-btn" >Share</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>

                            <div className="appfullcontainer mt-0 mb-0" >
                                <h3>Previous Bulletins</h3>
                                <div className="bulletin-block"> 
                                {this.state.bulletinNews.length>0 &&
                                    this.state.bulletinNews.map((bulletinobj, i)=> {
                                        if (i > 0) {
                                            return (
                                                <a key={i} href={"/Bulletin/" + bulletinobj.type + '/' + bulletinobj.BulletinID}>
                                                    <div className="card">
                                                        <div className="img-card">
                                                            <img src={bulletinobj.VideoPic} className="card-img-top" alt="image" />
                                                        </div>
                                                        
                                                        <div className="card-body">
                                                            <p className="tag">{bulletinobj.dateofpost}</p>
                                                        </div>
                                                    </div>
                                                </a> 
                                            )
                                        }
                                    })
                                }
                                
                                <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.bulletinNewsList.length > 0 && (!(this.state.bulletinNews.length > 1)) &&
                        <div className="tab-bulletin-main">
                            <div className="appfullcontainer mt-0 mb-1" >
                            {this.state.bulletinNewsList.length > 0 &&
                                this.state.bulletinNewsList.map((bnobj, i) => {
                                    return (
                                        <div className="live-block" key={i}>
                                            <div className="card">
                                                <a href={"/Bulletin/" + bnobj.type }>
                                                    <div className="img-card">
                                                        <img src={bnobj.VideoPic} className="card-img-top" alt="image" />
                                                    </div>

                                                    <div className="card-body">
                                                        <h5>{bnobj.language} Bulletin</h5>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    }
                </div>
                <Footer />
            </div>
        );
    }
}

export default Bulletins;