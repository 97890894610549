import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import { Redirect } from 'react-router-dom';
import { PostData } from './PostData';
import LazyLoad from "react-lazyload";

const Spinner = () => (
    <div className="post loading">
        <svg
            width="40"
            height="40"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#49d1e0"
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
                transform="rotate(275.845 50 50)"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    </div>
);

class ProgramsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            p_id: props.match.params.pid,
            cat_id: props.match.params.cid,
            Program : [],
            ProgramsList : [],
        }

        this.fetchProgramsDetail = this.fetchProgramsDetail.bind(this);
        this.shareClick = this.shareClick.bind(this);
    }

    componentDidMount() {
        this.fetchProgramsDetail()
    }

    fetchProgramsDetail() {
        let pdata = { id: this.state.p_id };
        PostData('Programs', pdata).then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({ Program: responseJson });
        });

        let cdata = { cid: this.state.cat_id, id: this.state.p_id};
        PostData('Programs', cdata).then((result) => {
            let responseJson = result;
            this.setState({ ProgramsList: responseJson });
        });
    }

    /* shareClick(e, share_url, share_title) {

        if (navigator.share) {
            navigator.share({
                title: share_title,
                url: share_url
            }).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
            console.log('device does not support sharing...');
        }
    } */
    shareClick(e, share_url, share_title, share_text = "", share_image = "") {
        fetch(share_image, {
            mode: 'cors',
        })
            .then(function (response) {
                console.log(response);
                return response.blob()
            })
            .then(function (blob) {
                var file = new File([blob], share_image, { type: 'image/jpeg' });
                var filesArray = [file];
                console.log(filesArray);

                if (navigator.canShare && navigator.canShare({ files: filesArray })) {
                    navigator.share({
                        text: share_text,
                        files: filesArray,
                        title: share_title,
                        url: share_url
                    }).then(() => {
                        console.log('Thanks for sharing!');
                    })
                        .catch(console.error);
                }
                else {
                    console.log('device does not support sharing...');
                }
            });
    }
    

    render() {
        return (
            <div id="home">
                <Header />
                <div id="appCapsule">
                    <Menu active="programs" />
                    
                    <div className="tab-program">
                        {this.state.Program.length >0
                            && this.state.Program.map((prg, i)=>{
                                let title_str = "GOA 365 Program: " + prg.title;
                                return (
                                    <div className="appfullcontainer mt-0 mb-1" key={i}>
                                        <div className="live-block">
                                            <div className="card">
                                                <a href="#">
                                                    <div className="img-card">
                                                        <iframe width="100%" height="250" src={prg.videolink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    </div>

                                                    <div className="card-body">
                                                        <p className="tag">{prg.cat_title}</p>
                                                        <h4 className="mb-0">{prg.title}</h4>
                                                    </div>
                                                </a>

                                                <div className="share-bx">
                                                    {/* <button onClick={(e) => this.shareClick(e, prg.r_url, title_str)} className="share-btn" >Share</button> */}
                                                    <button onClick={(e) => this.shareClick(e, prg.r_url, "", title_str, prg.VideoPic)} className="share-btn" >Share</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }

                        <div className="appfullcontainer mt-0 mb-0" >
                            <h3>Previous Programs</h3>
                            <div className="appContent" id="news-block">
                                {this.state.ProgramsList.length > 0
                                    && this.state.ProgramsList.map((progobj, i) => {
                                        return (
                                            <LazyLoad
                                                key={i}
                                                height={100}
                                                offset={[-100, 100]}
                                                placeholder={<Spinner />}
                                            >
                                            <div className="news-bx">
                                                    <a href={"/ProgramsDetail/" + progobj.cid + "/" + progobj.id} >
                                                    <div className="img-card">
                                                        <img src={progobj.VideoPic} className="card-img-top" alt="image" />
                                                    </div>

                                                    <div className="card-body">
                                                            <p className="tag">{progobj.date}</p>
                                                            <h4 className="mb-0">{progobj.title}</h4>
                                                    </div>
                                                    
                                                    <div className="clear"></div>
                                                </a>
                                            </div>
                                            </LazyLoad>
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        );
    }
}

export default ProgramsDetail;