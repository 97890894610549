import React, {Component} from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import {Redirect} from 'react-router-dom';
import { PostData } from './PostData';
import {Link} from 'react-router-dom';
import { InlineShareButtons } from 'sharethis-reactjs';

const ShareButtons = (props) => {
    let URL = "https://app.reismagos.org/";//(props.post.URL) ? 'https://www.heraldgoa.in/' + props.post.URL : 'https://www.heraldgoa.in/';
    let IMAGE = (props.image) ? props.image : '';
    let STRAP = (props.strapline) ? props.strapline : '';
    let NAME = "";//(props.short.NAME) ? props.short.NAME : '';

    console.log(IMAGE);

    return (
        <InlineShareButtons
            config={{
                alignment: 'center',  // alignment of buttons (left, center, right)
                color: 'social',      // set the color of buttons (social, white)
                enabled: true,        // show/hide buttons (true, false)
                font_size: 16,        // font size for the buttons
                labels: 'cta',        // button labels (cta, counts, null)
                language: 'en',       // which language to use (see LANGUAGES)
                networks: [           // which networks to include (see SHARING NETWORKS)
                    'whatsapp',
                    'facebook',
                    'twitter',
                    'messenger',
                    'linkedin'
                ],
                padding: 12,          // padding within buttons (INTEGER)
                radius: 4,            // the corner radius on each button (INTEGER)
                show_total: true,
                size: 40,             // the size of each button (INTEGER)

                // OPTIONAL PARAMETERS
                url: URL, // (defaults to current url)
                image: IMAGE,  // (defaults to og:image or twitter:image)
                description: STRAP,       // (defaults to og:description or twitter:description)
                title: NAME,            // (defaults to og:title or twitter:title)
                message: 'custom email text',     // (only for email sharing)
                subject: 'custom email subject',  // (only for email sharing)
                username: 'custom twitter handle' // (only for twitter sharing)
            }}
        />
    )
};

class Shorts extends Component {
	constructor() {
		super();
	    this.state= {
            data:[],
            shorts:[],
		}
		
        this.fetchShortsData = this.fetchShortsData.bind(this);
        // this.shareClick = this.shareClick.bind(this);
	}

	componentDidMount() {
		this.fetchShortsData()
	}

	fetchShortsData() {
		let data = { language:'E', type:""};
		PostData('Shorts', data).then((result) => {
			let responseJson = result;
			// console.log(responseJson);
			this.setState({shorts: responseJson});
		});
    }
    
    // shareClick(e, share_url, share_title, share_text="", share_image="") {        
    //     // console.log("url: "+share_url);
    //     // console.log("title: "+share_title);        
    //     if (navigator.share) {
    //         navigator.share({
    //             title: share_title,
    //             text: "Goa 365 SHORTS:"+share_text,
    //             // url: share_url
    //         }).then(() => {
    //             console.log('Thanks for sharing!');
    //         })
    //             .catch(console.error);
    //     } else {
    //         console.log('device does not support sharing...');
    //     }
    // }

    shareClick(e, share_url, share_title, share_text="", share_image=""){
        fetch(share_image,{
            mode: 'cors',
        })
        .then(function (response) {
            console.log(response);
            return response.blob()
        })
        .then(function (blob) {
            var file = new File([blob], share_image, { type: 'image/jpeg' });
            var filesArray = [file];
            console.log(filesArray);

            if (navigator.canShare && navigator.canShare({ files: filesArray })) {
                navigator.share({
                    text: share_text,
                    files: filesArray,
                    title: share_title,
                    url: "https://goa365.tv/"
                }).then(() => {
                    console.log('Thanks for sharing!');
                })
                    .catch(console.error); 
            }
            else {
                console.log('device does not support sharing...');
            }
        });
    }

	render() {
		return (
			<div id="shorts">
				<Header />
                <div id="appCapsule">
					<Menu active="shorts" />
                    
                    <div className="tab-shorts">
                        <div className="appfullcontainer mt-0 mb-1" >
                            {
                                this.state.shorts.length >0 &&
                                this.state.shorts.map((shortsobj, i) => {
                                    let share_title_str = "Goa 365 SHORTS: " + shortsobj.title;
                                    return (
                                        <div className="shorts-block" key={i}>
                                            <div className="card">
                                                <div className="img-card">
                                                    <img src={shortsobj.image} className="card-img-top" />
                                                </div>
                                                <div className="card-body">
                                                    <p className="tag">{shortsobj.datestart}</p>
                                                    {/* <p className="tag">today <span>11:45</span></p> */}
                                                    {/* <h4 className="mb-0">India reports more than 90k cases India reports more of covid19</h4> */}

                                                    {shortsobj.title && <p dangerouslySetInnerHTML={{ __html: shortsobj.title }} />}

                                                    {/* <div className="addthis_inline_share_toolbox">
                                                        <p></p>
                                                        <ShareButtons strapline={shortsobj.title} image={shortsobj.image} />
                                                    </div> */}

                                                    <div className="btn-block">
                                                        <button onClick={(e) => this.shareClick(e, "", "Goa 365 SHORTS: ", share_title_str, shortsobj.image)} className="btn-box" >Share</button>
                                                    </div>


                                                    {/* <div className="btn-block">
                                                        <a className="btn-box" href="#">Share</a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>   
                    </div>    
                </div>
				<Footer />
			</div>
		);
	}
}  

export default Shorts;