export function PostData(type, postData) {
	// let BaseURL = 'http://localhost/goa365/api/api.php';
	// let BaseURL = 'https://app.reismagos.org/api/api.php';
	let BaseURL = 'https://app.goa365.tv/api/api.php';
	// console.log(passeddata);

	return new Promise((resolve, reject) =>{

		/* fetch(BaseURL, {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(response => response.json())
			.then(data => {
				console.log('Success:', data);
			})
			.catch((error) => {
				console.error('Error:', error);
			}); */
		fetch(BaseURL + '?tp=' + type, {
			mode: 'cors',
			credentials: 'same-origin',
			method: "POST",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},

			body: JSON.stringify(postData)
		})
			.then((response) => response.json()
				.then((res) => {
					resolve(res);
				}))
			.catch((error) => {
				reject(error);
				//alert(error);
				//alert("Network Error: Please Check your Internet Connection");
			});
	});
}